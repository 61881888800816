<template>
  <footer class="footer mt-auto py-3">
    <b-container class="text-center">
      <p class="mb-0">&copy; {{ currentYear }} 合肥云茗网络科技有限公司 版权所有</p>
      <p class="mb-0 mt-1">
        <a href="https://beian.miit.gov.cn/" target="_blank" class="text-secondary">
          皖ICP备2024032169号-1
        </a>
      </p>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #f8f9fa;
}
.footer a {
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
}
</style> 
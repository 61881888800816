<template>
  <div id="app">
    <NavBar />
    <router-view />
    <div class="contact-info py-4 bg-light border-top">
      <b-container>
        <b-row>
          <b-col md="4" class="text-center mb-3 mb-md-0">
            <font-awesome-icon icon="phone" class="text-primary mr-2"/>
            联系电话：
            <a href="tel:19356065485" class="text-dark">19356065485</a>
          </b-col>
          <b-col md="4" class="text-center mb-3 mb-md-0">
            <font-awesome-icon icon="envelope" class="text-primary mr-2"/>
            电子邮箱：
            <a href="mailto:dyy2024898@163.com" class="text-dark">dyy2024898@163.com</a>
          </b-col>
          <b-col md="4" class="text-center">
            <font-awesome-icon :icon="['fab', 'weixin']" class="text-primary mr-2"/>
            微信同号：19356065485
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  }
}
</script>

<style>
#app {
  font-family: 'Microsoft YaHei', Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contact-info {
  margin-top: auto;
}

.contact-info a {
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
</style> 
<template>
  <div class="banner">
    <b-container fluid class="text-center text-white py-5">
      <h1 class="display-4 font-weight-bold mb-4">专注微信第三方服务平台</h1>
      <p class="lead mb-5">建站、教程、资源</p>
      <div class="button-group">
        <b-button variant="outline-light" class="mx-2 mb-2">
          <font-awesome-icon :icon="['fab', 'weixin']" class="mr-2"/>
          免费
        </b-button>
        <b-button variant="outline-light" class="mx-2 mb-2">
          <font-awesome-icon :icon="['fab', 'weixin']" class="mr-2"/>
          微信公众号
        </b-button>
        <b-button variant="outline-light" class="mx-2 mb-2">
          <font-awesome-icon :icon="['fab', 'weixin']" class="mr-2"/>
          微信小程序
        </b-button>
        <b-button variant="outline-light" class="mx-2 mb-2">
          <font-awesome-icon :icon="['fab', 'weixin']" class="mr-2"/>
          企业微信
        </b-button>
        <b-button variant="outline-light" class="mx-2 mb-2">
          <font-awesome-icon icon="code" class="mr-2"/>
          定制开发
        </b-button>
        <b-button variant="outline-light" class="mx-2 mb-2">
          <font-awesome-icon icon="shield-alt" class="mr-2"/>
          安全可靠
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>

<style scoped>
.banner {
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  padding: 80px 0;
}

.button-group {
  max-width: 800px;
  margin: 0 auto;
}
</style> 
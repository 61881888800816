<template>
  <div class="home">
    <Banner />
    <b-container class="py-5">
      <h2 class="text-center mb-5">我们的服务</h2>
      <b-row>
        <b-col md="4" class="mb-4">
          <b-card title="微信公众号">
            <b-card-text>
              专业的微信公众号开发服务，助力企业打造优质公众号平台。
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4" class="mb-4">
          <b-card title="小程序开发">
            <b-card-text>
              一站式小程序开发解决方案，让您的业务轻松触达移动端用户。
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4" class="mb-4">
          <b-card title="企业定制">
            <b-card-text>
              根据企业需求提供个性化定制开发服务，助力企业数字化转型。
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'

export default {
  name: 'Home',
  components: {
    Banner
  }
}
</script> 
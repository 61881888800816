<template>
  <b-navbar toggleable="lg" type="dark" class="nav-bg">
    <b-container>
      <b-navbar-brand to="/">
        <font-awesome-icon icon="cloud" class="mr-2" size="lg"/>
        合肥云茗网络科技有限公司
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/" exact>首页</b-nav-item>
          <b-nav-item-dropdown text="公司产品">
            <b-dropdown-item to="/products">产品列表</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="公司新闻">
            <b-dropdown-item to="/news">新闻动态</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/about">关于我们</b-nav-item>
          <b-nav-item to="/faq">常见问题</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style scoped>
.nav-bg {
  background: linear-gradient(135deg, #1e3c72, #2a5298);
}
</style> 